import { useReCaptcha } from 'vue-recaptcha-v3';
export const useRecaptchaToken = () => {
  const recaptchaInstance = useReCaptcha();

  const getRecaptchaToken = async (action: string) => {
    await recaptchaInstance?.recaptchaLoaded();

    return recaptchaInstance?.executeRecaptcha(action);
  };

  return { getRecaptchaToken };
};
